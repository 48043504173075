/**
 * @file standalone/content_block.scss
 *
 * shared styles for content blocks
 *
 */

@import '../theme-bootstrap';

.content-block {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0;
  &[data-clickable],
  [data-clickable] {
    cursor: pointer;
  }
  b,
  strong {
    font-weight: bold;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }
  &__content-wrapper {
    width: 100%;
    height: 100%;
    &--positioned {
      position: absolute;
      z-index: 3;
      top: 0;
      #{$ldirection}: 0;
    }
  }
  &__line {
    display: block;
    p {
      margin: 0;
      padding: 0;
    }
  }
  &__links {
    display: block;
  }
  &__link-wrapper {
    display: contents;
    &--column {
      display: flex;
      flex-direction: column;
      align-items: inherit;
    }
    &--row {
      display: flex;
      flex-direction: row;
      align-items: inherit;
    }
  }
  &--positioned {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
  }
  &__picture {
    &-media-asset {
      width: 100%;
      height: 100%;
      img,
      picture {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .basic-tout {
    &__wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    &__content-below-block {
      display: block;
    }
    &__content-wrapper {
      position: relative;
    }
    &__content-over-media {
      position: absolute;
      top: 0;
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
    }
  }
}
